import Vue from 'vue'
// Use it to import all Vue file containing this folder as Components
// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('home', require('./home').default);
Vue.component('pages-404', require('./utility/404').default);

Vue.component("login", require("./account/login").default);
Vue.component("register", require("./account/register").default);
Vue.component("forgot-password", require("./account/forgot-password").default);
Vue.component("reset-password", require("./account/reset-password").default);

Vue.component("clientes", require("./vistas/clientes").default);
Vue.component("productos", require("./vistas/productos").default);
Vue.component("licitaciones", require("./vistas/licitaciones").default);
Vue.component("marcas", require("./vistas/marcas").default);
Vue.component("despacho", require("./vistas/despacho").default);
Vue.component("analisis", require("./vistas/analisis").default);
Vue.component("csv", require("./vistas/csv").default);
Vue.component("mercado_publico", require("./vistas/mercado_publico").default);
