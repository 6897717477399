<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import Swal from "sweetalert2";
import {jsPDF} from 'jspdf'; 


export default {
    components: { Layout, PageHeader ,jsPDF},
    data() {
        return {
            title: "CLIENTES",
            items: [
                {
                text: "Tables",
                href: "/"
                },
                {
                text: "Advanced",
                active: true
                }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "id",
            sortDesc: true,
            fields: [
                { key: "codigo_externo",label:'Código Externo', sortable: true },
                { key: "nombre",label:'Nombre', sortable: true },
                { key: "codigo_estado", label:'Estado',sortable: true },
                { key: "licitacion_id", label:'ID Licitacion',sortable: true },
                { key: "fecha_cierre", label:'Fecha Cierre',sortable: true },
                { key: "accion", label:"Acciones"},
            ],
            mplistado:[],
            codigo_data:'',
            Componentes_Cliente:{
                id:'',
                nombre:null,
                rut:null,
                fantasia:null
            },
            id:'',
            licitaciones:[],
            licitacion_id:null,
            productos:[],
            a:[],
            formato_imagen:'',
            validador_RUT:'',
            Mostrar_cliente:false,
            Mostrar_boton_crear:true,
            showbutton_modificarcliente:false,
            isLoading:true,
            showModal: false,
            codigoLicitacion: ''
        };
    },
    computed: {
        rows() {
            return this.mplistado.length;
        }
    },
    mounted() {
        this.totalRows = this.items.length;
    },
    methods: {
        openModal() {
            this.$refs['Agregar_Licitacion'].show();
        },
        closeModal() {
            this.$refs['Agregar_Licitacion'].hide();
        },
        submitCodigo() {
            console.log(this.codigoLicitacion)
            this.get_data_mp_codigo(this.codigoLicitacion)
            this.closeModal();
        },
        camposmsg(text,icon) {
            Swal.fire({
                icon: icon,
                title: text,
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Volver",
                iconColor:"#34c38f"
            }).then(()=>{
                window.location.href = `/clientes`;
            });
        },
        validacionmsg(text,icon) {
            Swal.fire({
                icon: icon,
                title: text,
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Volver",
                iconColor:"#34c38f"
            });
        },
        eliminarmsg(text) {
            Swal.fire({
                icon: 'success',
                title: text,
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Volver"
            }).then(()=>{
                window.location.href = `/clientes`;
            });
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        Volver(){
            this.$refs['Ver_Licitacion'].hide();
            this.codigo_data=''
        },
        showModalver(data){
            this.isLoading=true
            //console.log(data)
            this.licitacion_id=data.licitacion_id
            if(data.codigo_data){
                this.codigo_data = JSON.parse(data.codigo_data)
                this.codigo_data.Listado[0].Items.Listado.forEach(element => {
                    element.cantidadAsociado = element.Cantidad 
                });
                //console.log(this.codigo_data);
                this.$refs['Ver_Licitacion'].show();
                this.isLoading=false
            }else{
                this.get_data_mp_codigo(data.codigo_externo)
                // axios.get('/get_mercado_publico/'+data.codigo_externo).then(res=>{
                //     if(res.data.estado){
                //         this.codigo_data = res.data.data
                //         console.log(this.codigo_data);
                //         this.isLoading=false
                //         this.$refs['Ver_Licitacion'].show();
                //         this.get
                //     }else{
                //         this.codigo_data=''
                //     }
                // })
            }
        },
        Eliminar(data){
            Swal.fire({
                title: '¿Estás seguro/a?',
                text: "Este cambio no puede ser revertido",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
            }).then((result) => {
                if (result.isConfirmed) {
                    // this.Componentes_Cliente={id:data.id,};
                    // axios.post('/api/eliminar_cliente',this.Componentes_Cliente).then(res=>{
                    //     if(res.data.estado){
                    //         this.eliminarmsg(res.data.mensaje);
                    //         this.Componentes_Cliente=
                    //             {
                    //             rut:null,
                    //             nombre:null,
                    //             };
                    //     }
                    // })
                }
            }) 
        },
        get_data_mp_codigo(codigo){
            this.isLoading=true
            axios.get('/get_mercado_publico/'+codigo).then(res=>{
                if(res.data.estado){
                    this.codigo_data = res.data.data
                    this.isLoading=false
                    console.log(this.codigo_data)
                    console.log(res.data.url)
                    this.$refs['Ver_Licitacion'].show();
                    this.get_data()
                    this.codigoLicitacion=''
                }else{
                    this.isLoading=false
                    this.codigo_data=''
                    console.log(res.data.error)
                    console.log(res.data.response)
                    this.$toasted.error('Código erróneo. Por favor, verifique y vuelva a intentarlo.', {
                        duration: 5000,  // Duración del mensaje en milisegundos (5 segundos)
                        keepOnHover: true,  // Mantiene el mensaje visible cuando el usuario lo pasa con el ratón
                        action: {
                            text: 'Cerrar',
                            onClick: (e, toastObject) => {
                                toastObject.goAway(0);  // Permite cerrar el mensaje al hacer clic en "Cerrar"
                            }
                        }
                    });
                }
            })
        },
        get_data_mp(){
            this.isLoading=true
            axios.get('/get_mercado_publico').then(res=>{
                if(res.data.estado){
                    this.mplistado=res.data.mplistado
                    this.isLoading=false
                }else{
                    console.log('error')
                    this.isLoading=false
                }
            })
        },
        get_data(){
            this.isLoading=true
            axios.get('/get_data_mercado_publico').then(res=>{
                if(res.data.estado){
                    this.mplistado=res.data.mplistado
                    this.isLoading=false
                }else{
                    console.log('error')
                    this.isLoading=false
                }
            })
        },
        GenerarLicitacion(data){
            data.user=this.user
            console.log(data.Listado[0].Items.Listado[0].CodigoProducto)
            axios.post('/api/crear_licitacion_mp',data).then(res=>{
                if(res.data.estado){
                    console.log(res.data.licitacion)
                    this.$refs['Ver_Licitacion'].hide();
                    this.get_data();
                }else{
                    console.log(res.data.error)
                }
            })
        }
    },
    created(){
        this.get_data();
        axios.get('/api/get_productos').then(res=>{
            this.productos = res.data.productos;
        })
    }
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <loading-message v-if="isLoading"/>
            <div class="col-12">
                <div class="card shadow-lg">
                    <div class="card-body">
                        <div class="row pr-1">
                            <div class="col-10">
                                <h4 class="card-title">Mercado Público</h4>
                            </div>
                            <button class="btn btn-primary" @click="get_data_mp">
                                <i class="fas fa-download"></i>Obtener Datos
                            </button>
                        </div>
                        <div>
                            <!-- Botón para abrir el modal -->
                            <button @click="openModal" class="btn btn-primary">Agregar Licitación</button>

                            <!-- Modal -->
                            <b-modal ref="Agregar_Licitacion" hide-header hide-footer size="xl">
                                <div class="card-body">
                                    <h2>Agregar Código de Licitación</h2>
                                    <b-input type="text" v-model="codigoLicitacion" placeholder="Ingrese el código"></b-input>
                                    <button class="btn btn-primary" :disabled="codigoLicitacion==''" @click="submitCodigo">Agregar</button>
                                    <button class="btn btn-primary" @click="closeModal">Cerrar</button>
                                </div>
                            </b-modal>
                        </div>
                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Mostrar&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Buscar:
                                        <b-form-input
                                        v-model="filter"
                                        type="search"
                                        placeholder="Buscar..."
                                        class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive mb-0">
                            <b-table class="table table-striped" hover
                                :items="mplistado"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                @filtered="onFiltered"
                            >
                                <template v-slot:cell(accion)="data">
                                    <b-button size="sm" :variant="data.item.codigo_data?'success':'info'" @click="showModalver(data.item)" ><i class="mdi mdi-eye font-size-18"></i></b-button>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" pagination-active-color="white"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <b-modal ref="Ver_Licitacion" hide-header hide-footer size="xl">
                            <div v-if="codigo_data==''">
                                <h2>Error al cargar datos</h2>
                            </div>
                            <div v-if="codigo_data">
                                <div class="row">
                                    <div class="col-12">
                                        <form class="form-horizontal" role="form">
                                            <div class="card-body">
                                                <div class="card-header">
                                                <h4>{{ codigo_data.Listado[0].Nombre }}</h4>
                                            </div>
                                            <div class="card-body">
                                                <p><strong>Código Externo:</strong> {{ codigo_data.Listado[0].CodigoExterno }}</p>
                                                <p><strong>Estado:</strong> {{ codigo_data.Listado[0].Estado }}</p>
                                                <p><strong>Descripción:</strong> {{ codigo_data.Listado[0].Descripcion }}</p>
                                                <p><strong>Días para Cierre:</strong> {{ codigo_data.Listado[0].DiasCierreLicitacion }}</p>
                                                <p><strong>Nombre del Organismo:</strong> {{ codigo_data.Listado[0].Comprador.NombreOrganismo }}</p>
                                                <p><strong>Rut:</strong> {{ codigo_data.Listado[0].Comprador.RutUnidad }}</p>
                                                <p><strong>Direccion:</strong> {{ codigo_data.Listado[0].Comprador.DireccionUnidad }}</p>
                                                <p><strong>Comuna:</strong> {{ codigo_data.Listado[0].Comprador.ComunaUnidad }}</p>
                                                <p><strong>Region:</strong> {{ codigo_data.Listado[0].Comprador.RegionUnidad }}</p>
                                                <p><strong>Fecha de Creación:</strong> {{ codigo_data.Listado[0].Fechas.FechaCreacion }}</p>
                                                <p><strong>Fecha de Cierre:</strong> {{ codigo_data.Listado[0].Fechas.FechaCierre }}</p>
                                                <!-- Puedes agregar más detalles según sea necesario -->
                                            </div>
                                                <div class="table-responsive">
                                                    <table class="table mb-0">
                                                        <thead>
                                                            <tr>
                                                                <!-- <th class="px-3">CodigoMP</th>
                                                                <th class="px-2">Nombre</th> -->
                                                                <th class="px-2">Descripcion</th>
                                                                <th class="px-0">Cantidad</th>
                                                                <th class="px-0">Producto Asociado</th> <!-- Nueva columna para el select -->
                                                                <th class="px-2">Valor</th> <!-- Nueva columna para el valor -->
                                                                <th class="px-4">Cantidad</th> 
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in codigo_data.Listado[0].Items.Listado" :key="item.id">
                                                                <!-- <td class="px-3">{{ item.CodigoProducto }}</td>
                                                                <td class="px-2">{{ item.NombreProducto }}</td> -->
                                                                <td class="px-2">{{ item.Descripcion }}</td>
                                                                <td class="px-0">{{ item.Cantidad }} {{ item.UnidadMedida }}</td>
                                                                <!-- Input asociado con datalist -->
                                                                <td class="px-0">
                                                                    <input v-model="item.codigoAsociado" type="text" class="inner form-control" placeholder="Producto" list="ListadoProductos"/>
                                                                    <datalist id="ListadoProductos">
                                                                        <option v-for="producto in productos" :key="producto.id" :value="producto.codigo">
                                                                            {{ producto.codigo }} - {{ producto.nombre }} - {{ producto.marca }}
                                                                        </option>
                                                                    </datalist>
                                                                </td>
                                                                <!-- Input para valor con ancho reducido -->
                                                                <td class="px-2">
                                                                    <input v-model="item.valorAsociado" type="text" class="form-control" placeholder="Valor">
                                                                </td>
                                                                <!-- Input para cantidad con ancho reducido -->
                                                                <td class="px-4">
                                                                    <input v-model="item.cantidadAsociado" type="number" class="form-control" placeholder="Cantidad">
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>      
                            <div class="text-right">
                                <b-button variant="info" @click="GenerarLicitacion(codigo_data)" :disabled="licitacion_id!=null">Generar Licitacion</b-button>
                                <b-button variant="info" @click="Volver()">Volver</b-button>
                            </div>
                        </b-modal>
                        <!-- <b-modal ref="Modificar_cliente" title="Modificar Cliente" hide-footer size="xl">
                            <div class="row">
                                <div class="col-12">
                                    <form class="form-horizontal" role="form">

                                        <b-form-group id="rut_id" label-cols-sm="2" label-cols-lg="2" label="Rut" label-for="text">
                                            <b-form-input for="text" v-model="Componentes_Cliente.rut" readonly></b-form-input>
                                        </b-form-group>

                                        <b-form-group id="Nombre_id" label-cols-sm="2" label-cols-lg="2" label="Razón social" label-for="text">
                                            <b-form-input for="text" v-model="Componentes_Cliente.nombre" readonly></b-form-input>
                                        </b-form-group>

                                        <b-form-group id="Nombre_id" label-cols-sm="2" label-cols-lg="2" label="Nombre fantasía" label-for="text">
                                            <b-form-input for="text" v-model="Componentes_Cliente.fantasia"></b-form-input>
                                        </b-form-group>

                                    </form>
                                </div>
                            </div>
                            <div class="text-right">
                                <b-button variant="info" @click="Volvereditar()">Volver</b-button>
                                <b-button variant="info" @click="Modificar()" :disabled="showbutton_modificarcliente">Modificar</b-button>
                            </div>
                        </b-modal> -->
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<style>
.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #50a5f1;  
}

.modal-header .close {
  display:none;
}

.small-input {
    width: 80px; /* Ajusta este tamaño según sea necesario */
}

.table td, .table th {
    padding: 5px; /* Reduce el padding a 5px, ajusta según sea necesario */
}

.table {
    margin: 0; /* Ajusta el margen si es necesario */
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

input {
  margin-bottom: 10px;
}

button {
  margin: 5px;
}


</style>