<script>
import Layout from "../layouts/main";
import PageHeader from "../components/page-header";
import Swal from "sweetalert2";
import VueHtml2pdf from 'vue-html2pdf';
import {jsPDF} from 'jspdf';

export default {
  
    components: { Layout, PageHeader,Swal,VueHtml2pdf,jsPDF },
    data() {
        return {
            title: "Starter Page",
            items: [
                {
                text: "Utility",
                href: "/"
                },
                {
                text: "Starter Page",
                active: true
                }
            ],
            licitaciones:[],
            productos:[],
            clientes:[],
            Componentes_Producto:{
                id:'',
                codigo:null,
                nombre:null,
                //descripcion:null,
                marca:null,
                fabricacion:null,
                vencimiento:null,
                //duracion:null,
                certificacion:null,
                presentacion:null,
                //envio:null,
                //plazoentrega:null,
                caracteristicas:null,
                imagen:null,
                dir:null,
            },
            marcas:'', 
            Componentes_Licitacion:{
                    id:'',
                    numero:null,
                    nombre:null,
                    cliente:null,
                    productos:null,
                    monto:null,
                    observaciones:null,
                    condicion:null,
            },
            phoneData: {
            },
            codigo:'',
            codigos:[],
            Componentes_Cliente:{
                id:'',
                nombre:null,
                rut:null,
                fantasia:null,
            },
            a:[],
            formato_imagen:'',
            paises:'',
            imagenminiatura:'', 
            cantidad_pendientes:0,
            Verificador_cliente:"",
            Mostrar_cliente:false,
            Mostrar_cliente:false,
            Mostrar_boton_crear:true, 
            checked:false,
            showbutton_crearproducto:false,
            showbutton_crearlicitacion:false,
            totalRows: 1,
            currentPage: 1,
            perPage2: 10,
            pageOptions2: [10, 25, 50, 100], 
            filter: null,
            filterOn: [],
            sortBy: "age",
            sortDesc: false, 
            campos_productos: [
                { key: "phone",label:'Nombre', sortable: true },
                { key: "cantidad", label:'Cantidad',sortable: true },
                { key: "precio", label:'Precio',sortable: true },
                { key: "accion", label:"Acciones"},
            ],  
            productos_tabla:[], 
            SelectedProducto:'',
            CantidadModificar:'',
            PrecioModificar:'',
            EstadoProducto:false,
            AlertaCantidad:false,
            AlertaPrecio:false,     
        };
    },
    watch: {
        Verificador_cliente: function(value,Oldvalue){
            //verifique que hay guion
            if(value.includes('-')){
                //console.log('tiene guion');
                if(value.indexOf('-')!=0){
                    //console.log('el guion no es la primera letra');
                    if((value.indexOf('-')+1)==value.length){
                        //console.log('el guion al final');
                    }else{
                        //console.log('el guion no al final');
                        let vista_toasted=0
                        for (let i = 0; i < this.clientes.length; i++) {
                            const element = this.clientes[i];
                            if(value==element.rut){
                                //console.log('rut correcto');
                                this.Mostrar_cliente=true
                                vista_toasted=0
                                this.Componentes_Licitacion.cliente=element.nombre
                                //console.log(this.Componentes_Licitacion.cliente);
                                break;
                            }else{
                                
                                vista_toasted=1
                                this.Mostrar_cliente=false
                                this.Componentes_Licitacion.cliente=[]
                            }  
                        }
                        if(vista_toasted!=0){
                            this.$toasted.error('rut incorrecto');
                        }else{
                            this.$toasted.success('rut correcto');
                        } 
                    }
                }else{
                    //console.log('el guion es la primera letra');
                }
            }else{
                //console.log('no tiene guion');
            }  
        },
        checked: function(value,Oldvalue){
            //console.log(value);
            //console.log(Oldvalue);
            if(value){
                this.Componentes_Producto.vencimiento='NO APLICA'
                //console.log(this.Componentes_Producto.vencimiento);
            }else{
                this.Componentes_Producto.vencimiento=null
            }
        }
    },
    computed: {
        rows() {
        return this.productos_tabla.length;
        },
        imagen(){
        return this.imagenminiatura;
        },
        Total_licitacion () {
            let valorparcial = 0
            this.productos_tabla.forEach(element => {
                valorparcial = parseInt(valorparcial) + (parseInt(element.precio)* parseInt(element.cantidad))
            });
            if(Number.isNaN(valorparcial)){
                return 'Ingresar valor númerico'
            }else{
                return Intl.NumberFormat("de-DE").format(valorparcial)
            }   
        }, 
    },
    mounted() {
        this.totalRows2 = this.items.length;
    },
    methods:{
        AddPhoneNo() {
            //console.log(this.phoneData);
            var contador = 0
            if(this.phoneData.cantidad==''){
                contador = contador +1
                return Swal.fire({
                icon: "warning",
                title: "Cantidad vacía",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Volver",
                iconColor:"#34c38f"
                })
            }
            if(this.phoneData.precio==''){
                contador = contador +1
                return Swal.fire({
                icon: "warning",
                title: "Precio vacío",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Volver",
                iconColor:"#34c38f"
                })
            }
            this.productos_tabla.forEach(item => {
                //console.log(item);
                if(item.phone == this.phoneData.phone){
                    contador = contador +1
                    return Swal.fire({
                    icon: "warning",
                    title: "Producto repetido",
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "Volver",
                    iconColor:"#34c38f"
                    })
                }
            });
            if(contador ==0){
                this.productos_tabla.push(this.phoneData)
                this.phoneData={
                    phone:'',
                    cantidad:'',
                    precio:''
                }
            }
            //console.log(this.productos_tabla);
        },
        deletePhone(data) {
            //console.log(data.phone);
            this.productos_tabla.forEach(function(item ,index,object)  {
                if(item.phone == data.phone){
                    /* console.log('eliminar');
                    console.log(index); */
                    object.splice(index, 1) 
                }
            });
        },
        onFiltered2(filteredItems) {
        this.totalRows2 = filteredItems.length;
        this.currentPage = 1;
        },
        CrearProductomodal(){
            this.$refs['Crear_Producto'].show();
        },
        CrearProducto(){
            this.showbutton_crearproducto=true
            let final_caracteristicas=0
            if(this.Componentes_Producto.caracteristicas!= '' || this.Componentes_Producto.caracteristicas!= null || this.Componentes_Producto.caracteristicas!= 'null'){
                let regEx = new RegExp('\\n','g')
                let texto_lineas = this.Componentes_Producto.caracteristicas
                let valor_amostrar = this.Componentes_Producto.caracteristicas
                let a = 0
                //contar saltos de linea
                let valor_propiedad = this.Componentes_Producto.caracteristicas.replace(regEx,'@')
                for (let i = 0; i < valor_propiedad.length; i++) {
                    if(valor_propiedad.charAt(i) ==='@'){
                        a = a +1
                    }
                    
                }
                //contar lineas
                let z = 0
                let regEx2 = new RegExp('\\s+','g')
                let valor_propiedad_2 = texto_lineas.replace(regEx2,' ')
                final_caracteristicas = valor_propiedad_2.length
                z = Math.trunc(parseInt(final_caracteristicas)/104)
                let y=a+z
                //console.log(y);
                if(y>14){
                    //console.log('entramos al alert')
                    this.showbutton_crearproducto=false
                    return this.validacionmsg('Máximo de lineas alcanzado','warning');
                }else {
                    //console.log('entramos al seteo')
                    this.Componentes_Producto.caracteristicas = valor_amostrar
                    //console.log(this.Componentes_Producto)
                    if(this.Componentes_Producto.codigo == null || this.Componentes_Producto.nombre == null || this.Componentes_Producto.imagen == null || this.Componentes_Producto.nombre == ''|| this.Componentes_Producto.codigo == ''){
                        this.showbutton_crearproducto=false
                        return this.validacionmsg('No puede haber campos vacios','warning');
                    }else{
                        let formData = new FormData();
                        formData.append('id', this.Componentes_Producto.id);
                        formData.append('codigo', this.Componentes_Producto.codigo);
                        formData.append('nombre', this.Componentes_Producto.nombre);
                        //formData.append('descripcion', this.Componentes_Producto.descripcion);
                        formData.append('marca', this.Componentes_Producto.marca);
                        formData.append('fabricacion', this.Componentes_Producto.fabricacion);
                        formData.append('vencimiento', this.Componentes_Producto.vencimiento.split('-').reverse().join('-'));
                        //formData.append('duracion', this.Componentes_Producto.duracion);
                        formData.append('certificacion', this.Componentes_Producto.certificacion);
                        formData.append('presentacion', this.Componentes_Producto.presentacion);
                        //formData.append('envio', this.Componentes_Producto.envio);
                        formData.append('img', this.Componentes_Producto.imagen);
                        //formData.append('plazoentrega', this.Componentes_Producto.plazoentrega);
                        formData.append('caracteristicas', this.Componentes_Producto.caracteristicas);


                        axios.post('/api/crear_productos',formData).then(res=>{
                            if(res.data.estado){
                                this.camposmsg(res.data.mensaje,res.data.icon,2);
                                this.Componentes_Producto=
                                    {codigo:null,
                                    nombre:null,
                                    //descripcion:null,
                                    marca:null,
                                    fabricacion:null,
                                    vencimiento:null,
                                    //duracion:null,
                                    certificacion:null,
                                    presentacion:null,
                                    //envio:null,
                                    //plazoentrega:null,
                                    caracteristicas:null,
                                    dir:res.data.dir, 
                                    };        
                                console.log(this.Componentes_Producto.dir)
                            }
                        })

                    }
                }
            }
                
        },
        camposmsg(text,icon,redireccion) {
            Swal.fire({
                icon: icon,
                title: text,
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Volver",
                iconColor:"#34c38f",
                timer: 2500,
            }).then(()=>{
                if(redireccion == 1){
                    window.location.href = `/licitaciones`;
                }else if(redireccion == 2){
                    window.location.href = `/productos`;
                }else{
                    window.location.href = `/clientes`;
                }
                
            });
        },
        validacionmsg(text,icon,mensaje) {
            if(mensaje!=undefined){
                Swal.fire({
                icon: icon,
                title: text,
                html: mensaje.map(item => '<li>'+item+'</li>').join(''),
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Volver",
                iconColor:"#34c38f",
            });
            }else{
                Swal.fire({
                icon: icon,
                title: text,
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Volver",
                iconColor:"#34c38f"
            });
            }
        },
        Volver(){
            this.Componentes_Producto=
                {codigo:null,
                nombre:null,
                //descripcion:null,
                marca:null,
                fabricacion:null,
                vencimiento:null,
                //duracion:null,
                certificacion:null,
                presentacion:null,
                //envio:null,
                //plazoentrega:null,
                caracteristicas:null,
                };
            this.$refs['Crear_Producto'].hide()
            this.$refs['Crear_Cliente'].hide()
            this.Mostrar_cliente=false
            this.Componentes_Cliente={
                rut:null,
                nombre:null,
                fantasia:null
            };
        },
        CrearLicitacionmodal(){
            this.Componentes_Licitacion={
                numero:null,
                nombre:null,
                cliente:null,
                productos:null,
                observaciones:null,
                condicion:null,
            },
            this.phoneData=[]
            this.productos_tabla=[]
            this.$refs['Crear_Licitacion'].show();
        },
        Crearlicitacion(){
            if(this.Componentes_Licitacion.numero == null || this.Componentes_Licitacion.nombre == null || this.Componentes_Licitacion.cliente == null || this.Componentes_Licitacion.cliente == '' || this.Componentes_Licitacion.cliente == 'null' || this.productos_tabla.length < 1 || this.Componentes_Licitacion.numero == ''){
                return this.validacionmsg('No puede haber campos vacios','warning');
            }
            this.codigos=[]
            this.productos_tabla.forEach(element_vista => {
                this.codigo = element_vista.phone.split(' -')
                this.productos.forEach(element => {
                    if(element.codigo == this.codigo[0]){
                        element.cantidad=element_vista.cantidad
                        element.precio=element_vista.precio
                        this.codigos.push(element)  
                    }
                });
            });
            this.Componentes_Licitacion.productos=this.codigos
            //console.log(this.Componentes_Licitacion)
            // console.log('codigos '+this.Componentes_Licitacion.productos);
            this.$refs['Vista_Licitacion'].show();
        },
        Volvermodal_1(){
            this.Componentes_Licitacion={
            numero:null,
            nombre:null,
            cliente:null,
            observaciones:null,
            condicion:null,
            },
            this.phoneData={
                phone:'',
                precio:'',
                cantidad:''
            },
            this.productos_tabla=[]
            //console.log('volver'+' '+this.phoneData)
            this.Verificador_cliente=''
            this.$refs['Crear_Licitacion'].hide()           
        },
        Volver_vistaprevia(){
            //console.log('volver'+' '+this.phoneData)
            this.codigos=[]
            //console.log('codigos '+this.codigos);
            this.Componentes_Licitacion.productos=[]
            //console.log('productos '+this.Componentes_Licitacion.productos)     
            this.$refs['Vista_Licitacion'].hide()
            
        },
        Crearlicitacion_final(){
            this.showbutton_crearlicitacion=true
            axios.post('/api/crear_licitacion',this.Componentes_Licitacion).then(res=>{
                if(res.data.estado){
                    this.camposmsg('Licitacion creada correctamente','warning',1)
                }
                /**
                 * ocultar /se deja la exportacion en pdf oculta if = false.
                 */
                if(false){
                    //this.$refs.html2Pdf.generatePdf();
                    var doc = new jsPDF({ putOnlyUsedFonts: false, orientation: "portrait" });
                    doc.setFontSize(10);
                    doc.setFont("helvetica", "bold");
                    doc.addImage("/archivos_base/image001.png", "PNG", 85, 3, 40, 30);
                    doc.text("Número : "+this.Componentes_Licitacion.numero, 20, 35);
                    doc.text("Nombre : "+this.Componentes_Licitacion.nombre, 20, 40);
                    doc.text("Cliente : "+this.Componentes_Licitacion.cliente, 20, 45);
                    doc.text("Productos : ", 20, 50);
                    var a = 60
                    doc.setFont("helvetica", "normal");
                    this.Componentes_Licitacion.productos.forEach(element => {
                        console.log(element);
                        console.log('inicio'+this.formato_imagen);
                        if(element.foto_dir.includes('png')){
                            this.formato_imagen='PNG'  
                        }
                        if(element.foto_dir.includes('jpg')){
                            this.formato_imagen='JPG'
                        }
                        if(element.foto_dir.includes('jpeg')){
                            this.formato_imagen='JPEG'
                        }
                        console.log('despues'+this.formato_imagen);
                        doc.addImage(element.foto_dir, this.formato_imagen, 20, a, 170, 60);
                        var b=a+60

                        for (const property in element){
                            console.log('property'+property);
                            console.log('value:'+element[property]);
                            if(element[property] != 'null' &&  property != 'id' &&  property != 'foto' &&  property != 'foto_dir' &&  property != 'activo' &&  property != 'user' &&  property != 'created_at' &&  property != 'updated_at' &&  property != 'descripcion' &&  property != 'duracion' &&  property != 'envio' &&  property != 'plazoentrega'){
                                b=b+10
                                if(property =='codigo'){
                                    doc.text("Código: "+element[property], 20, b)
                                }
                                if(property =='nombre'){
                                    doc.text("Nombre: "+element[property], 20, b)
                                }
                                /*
                                if(property =='descripcion'){
                                    doc.text("Descripción: "+element[property], 20, b)
                                }
                                */
                                if(property =='marca'){
                                    doc.text("Marca: "+element[property], 20, b)
                                }
                                if(property =='fabricacion'){
                                    doc.text("Fabricación: "+element[property], 20, b)
                                }
                                if(property =='vencimiento'){
                                    doc.text("Vencimiento: "+element[property], 20, b)
                                }
                                /*
                                if(property =='duracion'){
                                    doc.text("Duración: "+element[property], 20, b)
                                }
                                */
                                if(property =='certificacion'){
                                    doc.text("Certificación: "+element[property], 20, b)
                                }
                                if(property =='presentacion'){
                                    doc.text("Presentación: "+element[property], 20, b)
                                }
                                /*
                                if(property =='envio'){
                                    doc.text("Envío: "+element[property], 20, b)
                                }
                                if(property =='plazoentrega'){
                                    doc.text("Plazo de entrega: "+element[property], 20, b)
                                }
                                */
                                if(property =='caracteristicas'){
                                    doc.text("Caracteristicas: "+element[property], 20, b)
                                }
                            }
                        }
                        doc.addPage();    
                    });
                        doc.setFontSize(10);
                        doc.addImage("/archivos_base/logo.png", "PNG", 85, a+165, 40, 20);
                        doc.text("Dirección: Errázuriz 2066 – Valdivia", 105, a+190, null, null, "center");
                        doc.text("Teléfono: +56 63 2 204385", 105, a+200, null, null, "center");
                        doc.text("Email: contacto@vamproden.cl", 105, a+210, null, null, "center");
                        doc.text("www.vamproden.cl", 105, a+220, null, null, "center");
                    
                    doc.save(this.Componentes_Licitacion.numero+'_'+this.Componentes_Licitacion.nombre);
                    this.camposmsg('Licitacion creada correctamente','warning',1)
                }
            })
        },
        CrearClientemodal(){
            this.$refs['Crear_Cliente'].show();
        },
        CrearCliente(){
            this.Mostrar_boton_crear=true
            if(this.Componentes_Cliente.nombre == null || this.Componentes_Cliente.rut == null){
                this.Mostrar_boton_crear=false
                return this.validacionmsg('No puede haber campos vacios','warning');
            }else{
                //console.log(this.Componentes_Cliente);
                axios.post('/api/crear_cliente',this.Componentes_Cliente).then(res=>{
                    if(res.data.estado){
                        this.camposmsg(res.data.mensaje,res.data.icon,'3');
                        this.Componentes_Cliente=
                            {
                            rut:null,
                            nombre:null,
                            fantasia:null,
                            };
                    }
                })
            }
        },
        validarrut(){  
            //console.log(this.Componentes_Cliente.rut); 
            let data={
                rut:this.Componentes_Cliente.rut,
                url:'https://api.libreapi.cl/rut/activities?rut='
            }
            axios.post('/api/get_url',data).then(res=>{
                //console.log(res.data.response);
                if(res.data.response.status!='fail'){
                    //console.log(res.data.response.data.name);
                    if(res.data.response.data.name===""){
                        //console.log('rut incorrecto');
                        this.$toasted.error('rut incorrecto');
                        this.Mostrar_cliente=false
                        this.Mostrar_boton_crear=true
                        this.Componentes_Cliente.nombre=null
                    }else{
                        //console.log('rut correcto');
                        //console.log(res.data.response);
                        this.Componentes_Cliente.nombre=res.data.response.data.name
                        this.$toasted.success('rut correcto');
                        this.Mostrar_cliente=true
                        this.Mostrar_boton_crear=false
                        //this.Crear();
                    }
                }
                else{
                    this.$toasted.error('rut incorrecto');
                    this.Mostrar_cliente=false
                    this.Mostrar_boton_crear=true
                    //console.log(res.data.response.status);
                }
                }).catch(error => console.log(error))       
        },
        obtenerImagen(e){
        let file = e.target.files[0];
        //console.log(file);
        this.Componentes_Producto.imagen = file;
        //console.log(this.data.imagen);
        this.cargarImagen(file);
        },
        cargarImagen(file){
        let reader = new FileReader(); 
        reader.onload = (e) =>{
            this.imagenminiatura = e.target.result;
        }
        reader.readAsDataURL(file);
        },
        editarPhone(index){
            //console.log(index);
            this.SelectedProducto = (this.currentPage-1)*this.perPage2+index
            this.CantidadModificar = this.productos_tabla[this.SelectedProducto].cantidad
            //console.log(this.CantidadModificar);
            this.CantidadPrecio = this.productos_tabla[this.SelectedProducto].precio
            //console.log(this.CantidadPrecio);
            if(this.CantidadModificar && this.CantidadPrecio){
                this.EstadoProducto=true
            }
            //console.log(this.EstadoProducto);
            this.$refs['Editar_Productos_Licitacion'].show();
        },
        actualizar_producto(){
            if(this.CantidadModificar && this.CantidadPrecio){
                this.productos_tabla[this.SelectedProducto].cantidad = this.CantidadModificar
                this.productos_tabla[this.SelectedProducto].precio= this.CantidadPrecio 
                this.AlertaCantidad = false
                this.AlertaPrecio = false
            }else{
                if(!this.CantidadModificar){
                    this.AlertaCantidad = true
                }
                if(!this.CantidadPrecio){
                    this.AlertaPrecio = true
                }
            }
        },
        Hidemodal(data){
            if(data == 1){
                this.SelectedProducto = ''
                this.CantidadModificar = ''
                this.CantidadPrecio = ''
                this.EstadoProducto = false
                this.AlertaCantidad = false
                this.AlertaPrecio = false
                this.$refs['Editar_Productos_Licitacion'].hide();
            }
        },
    },
    created() {
        axios.get('/api/get_licitaciones').then(res=>{
            this.licitaciones = res.data.licitaciones;
        })
        axios.get('/api/get_clientes').then(res=>{
            this.clientes = res.data.clientes;
        })
        axios.get('/api/get_productos').then(res=>{
            this.productos = res.data.productos;
            
        })
        axios.get('/api/get_marcas').then(res=>{
            this.marcas = res.data.marcas;
        })
        axios.get('/api/get_paises').then(res=>{
            this.paises = res.data.paises;
        })
        axios.get('/api/get_no_despachados').then(res=>{
            //console.log(res.data);
            this.cantidad_pendientes=res.data.nodespachados
        })
    },
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="col-xl-12">
            <div class="row">
                <div class="col-sm-4">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex align-items-center mb-3">
                                <div class="avatar-xs mr-3">
                                    <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                                        <i class="bx bxs-purchase-tag-alt"></i>
                                    </span>
                                </div>
                                <a  href="/productos" class="font-size-20 mb-0">Productos</a>
                            </div>
                            <div class="row text-muted mt-4">
                                <div class="col-md-12 text-center">
                                    <h4 class="text-primary">Total: {{productos.length}}</h4>
                                    <b-button  variant="info" @click="CrearProductomodal()">Agregar Producto</b-button>
                                </div>      
                            </div>
                        </div>
                    </div>
                </div>
                <b-modal ref="Crear_Producto" title="Crear producto" hide-footer size="xl">
                    <div class="row">
                        <div class="col-12">
                            <form class="form-horizontal" role="form">
                                <b-form-group id="Codigo_id" label-cols-sm="2" label-cols-lg="2" label="Código:" label-for="text">
                                    <b-form-input id="Codigo_imput_id" for="text" v-model="Componentes_Producto.codigo" maxlength="60" ></b-form-input>
                                    <div class="text-left">
                                        <p v-if="Componentes_Producto.codigo" class="badge position-absolute" :class="{ 'badge-primary': Componentes_Producto.codigo.length !== 60,
                                        'badge-danger': Componentes_Producto.codigo.length ===60 }">
                                            {{ Componentes_Producto.codigo.length }} /
                                            60
                                        </p>
                                    </div>
                                </b-form-group>
                                <b-form-group id="Nombre_id" label-cols-sm="2" label-cols-lg="2" label="Nombre:" label-for="text">
                                    <b-form-input for="text" v-model="Componentes_Producto.nombre" maxlength="60"></b-form-input>
                                    <div class="text-left">
                                        <p v-if="Componentes_Producto.nombre" class="badge position-absolute" :class="{ 'badge-primary': Componentes_Producto.nombre.length !== 60,
                                        'badge-danger': Componentes_Producto.nombre.length ===60 }">
                                            {{ Componentes_Producto.nombre.length }} /
                                            60
                                        </p>
                                    </div>
                                </b-form-group>
                                <!--
                                <b-form-group id="Descripcion_id" label-cols-sm="2" label-cols-lg="2" label="Descripción" label-for="text">
                                    <b-form-input for="text" v-model="Componentes_Producto.descripcion"></b-form-input>
                                </b-form-group>
                                -->
                                <div class="form-group row">
                                    <label class="col-md-2 col-form-label">Marca:</label>
                                    <div class="col-md-10">
                                        <select class="form-control" v-model="Componentes_Producto.marca">
                                            <option :value="null">Seleccion marca</option>
                                            <option value="sin marca">Sin marca</option>
                                            <option v-for="item in marcas" :key="item.id">{{item.nombre}} </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-2 col-form-label">Fabricación:</label>
                                    <div class="col-md-10">
                                        <select class="form-control" v-model="Componentes_Producto.fabricacion">
                                            <option :value="null">Seleccion pais:</option>
                                            <option value="sin marca">Sin Pais</option>
                                            <option v-for="item in paises" :key="item.id" >{{item.nombre}} </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-2 col-form-label">Vencimiento</label>
                                    <div class="col-md-4">
                                        <b-form-input id="Vencimiento_imput_id" v-model="Componentes_Producto.vencimiento" type="date" :disabled="checked"></b-form-input>
                                    </div>
                                    <div class="col-md-5">
                                        <b-form-checkbox v-model="checked" switch class="mb-1 mt-2">
                                            <label>Sin vencimiento</label>
                                        </b-form-checkbox>
                                    </div>
                                </div>
                                <!--
                                <b-form-group id="Duracion_id" label-cols-sm="2" label-cols-lg="2" label="Duración" label-for="text">
                                    <b-form-input for="text" v-model="Componentes_Producto.duracion"></b-form-input>
                                </b-form-group>
                                -->
                                <b-form-group id="Certificación_id" label-cols-sm="2" label-cols-lg="2" label="Certificación:" label-for="text">
                                    <b-form-input for="text" v-model="Componentes_Producto.certificacion" maxlength="60"></b-form-input>
                                    <div class="text-left">
                                        <p v-if="Componentes_Producto.certificacion" class="badge position-absolute" :class="{ 'badge-primary': Componentes_Producto.certificacion.length !== 60,
                                        'badge-danger': Componentes_Producto.certificacion.length ===60 }">
                                            {{ Componentes_Producto.certificacion.length }} /
                                            60
                                        </p>
                                    </div>
                                </b-form-group>
                                <b-form-group id="Presentación_id" label-cols-sm="2" label-cols-lg="2" label="Presentación:" label-for="text">
                                    <b-form-input for="text" v-model="Componentes_Producto.presentacion" maxlength="180"></b-form-input>
                                    <div class="text-left">
                                        <p v-if="Componentes_Producto.presentacion" class="badge position-absolute" :class="{ 'badge-primary': Componentes_Producto.presentacion.length !== 180,
                                        'badge-danger': Componentes_Producto.presentacion.length ===180 }">
                                            {{ Componentes_Producto.presentacion.length }} /
                                            180
                                        </p>
                                    </div>
                                </b-form-group>
                                <!--
                                <b-form-group id="Envio_id" label-cols-sm="2" label-cols-lg="2" label="Envío" label-for="text">
                                    <b-form-input for="text" v-model="Componentes_Producto.envio"></b-form-input>
                                </b-form-group>
                                <b-form-group id="plazoentrega_id" label-cols-sm="2" label-cols-lg="2" label="Plazo entrega" label-for="text">
                                    <b-form-input for="text" v-model="Componentes_Producto.plazoentrega"></b-form-input>
                                </b-form-group>
                                -->
                                <b-form-group id="Caracteristicas_id" label-cols-sm="2" label-cols-lg="2" label="Caracteristicas:" label-for="text">
                                    <textarea class="form-control" for="text" v-model="Componentes_Producto.caracteristicas" maxlength="1500" rows="15"></textarea>
                                            <div class="text-left">
                                                <p v-if="Componentes_Producto.caracteristicas" class="badge position-absolute" :class="{ 'badge-primary': Componentes_Producto.caracteristicas.length !== 1500,
                                                'badge-danger': Componentes_Producto.caracteristicas.length ===1500 }">
                                                    {{ Componentes_Producto.caracteristicas.length }} /
                                                    1500
                                                </p>
                                            </div>
                                </b-form-group>
                                <b-form-group id="example" label-cols-sm="2" label-cols-lg="2" label-for="text" label="Imagen:">
                                    <b-form-file
                                    v-model="Componentes_Producto.imagen"
                                    accept="image/png,image/jpeg,image/jpg"
                                    placeholder="Elegir Archivo"
                                    drop-placeholder="Drop file here..."
                                    @change="obtenerImagen"
                                    browse-text="Examinar"                             
                                    ></b-form-file>
                                </b-form-group>
                                <div class="text-center">
                                    <figure v-if="imagen==''">
                                        <p>Para visualizar, seleccione archivo de imagen</p>
                                    </figure>
                                    <figure v-if="imagen!=''">
                                        <img :src="imagen" height="200" alt="Imagen del producto">
                                    </figure>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="text-right">
                        <b-button variant="info" @click="Volver()">Volver</b-button>
                        <b-button variant="info" @click="CrearProducto()" :disabled="showbutton_crearproducto" >Crear</b-button>
                    </div>
                </b-modal>
                <div class="col-sm-4">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex align-items-center mb-3">
                                <div class="avatar-xs mr-3">
                                    <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                                        <i class="bx bxs-book-content"></i>
                                    </span>
                                </div>
                                <a href="/licitaciones" class="font-size-20 mb-0">Licitaciones</a>
                            </div>
                            <div class="row text-muted mt-4">
                                <div class="col-md-12 text-center">
                                    <h4 class="text-primary">Total: {{licitaciones.length}}</h4>
                                    <b-button  variant="info" @click="CrearLicitacionmodal()">Agregar Licitación</b-button>
                                </div>      
                            </div>
                        </div>
                    </div>
                </div>
                <b-modal ref="Crear_Licitacion" title="Crear Licitación" hide-footer size="xl"  :no-close-on-backdrop="true" @hidden="Volvermodal_1()" >
                    <div class="row">
                        <div class="col-12">
                            <form class="form-horizontal" role="form">
                                <b-form-group id="codigo_id" label-cols-sm="2" label-cols-lg="2" label="ID" label-for="text">
                                    <b-form-input for="text" v-model="Componentes_Licitacion.numero" placeholder="Código..."></b-form-input>
                                </b-form-group>
                                <b-form-group id="Nombre_id" label-cols-sm="2" label-cols-lg="2" label="Nombre" label-for="text">
                                    <b-form-input for="text" v-model="Componentes_Licitacion.nombre" placeholder="Nombre..."></b-form-input>
                                </b-form-group>

                                <b-form-group id="Rut" label-cols-sm="2" label-cols-lg="2" label="Buscar Cliente" label-for="text">
                                    <b-form-input for="text" v-model="Verificador_cliente" placeholder="Ingrese R.U.T..."></b-form-input>
                                </b-form-group>

                                <b-form-group id="Rut" label-cols-sm="2" label-cols-lg="2" label="Cliente" label-for="text" v-if="Mostrar_cliente">
                                    <b-form-input for="text" v-model="Componentes_Licitacion.cliente" placeholder="..." readonly></b-form-input>
                                </b-form-group>

                                <b-form-group id="condicion" label-cols-sm="2" label-cols-lg="2" label="Condición" label-for="text">
                                    <b-form-input for="text" v-model="Componentes_Licitacion.condicion" placeholder="Ingrese condición de despacho..." maxlength="40"></b-form-input>
                                </b-form-group>

                                <b-form-group id="Observaciones_id" label-cols-sm="2" label-cols-lg="2" label="Observaciones" label-for="text">
                                    <b-form-input for="text" v-model="Componentes_Licitacion.observaciones" placeholder="Esta información es solo para control interno, no se visualizará en la exportación..."></b-form-input>
                                </b-form-group>

                                <b-form-group id="numero_id" label-cols-sm="5" label-cols-lg="2" label="Monto licitación:" label-for="number">
                                    <p class="pt-2">{{"$"+Total_licitacion}}</p>
                                </b-form-group>

                                <div class="inner-repeater mb-4">
                                    <div class="inner form-group">
                                        <label>Agregar productos :</label>
                                        <div class="inner mb-3 row">
                                            <div class="col-md-6 col-8">
                                                <input v-model.lazy="phoneData.phone" type="text" class="inner form-control" placeholder="Producto" list="datalistOptions2"/>
                                                <datalist id="datalistOptions2" >
                                                    <option v-for="item in productos" :key="item.id"> {{item.codigo}} - {{item.nombre}} - {{item.marca}}</option>
                                                </datalist>
                                            </div>
                                            <div class="col-md-2 col-4">
                                                <b-form-input id="numero_imput_id" v-model.lazy="phoneData.cantidad"  type="number" name="number" placeholder="Cantidad" min="0"></b-form-input>
                                            </div>
                                            <div class="col-md-2 col-4">
                                                <b-form-input id="numero_imput_id" v-model.lazy="phoneData.precio"  type="number" name="number" placeholder="Precio" min="0"></b-form-input>
                                            </div>                                               
                                            <div class="col-md-2 col-4">
                                                <b-button variant="success" block @click="AddPhoneNo()"><i class="bx bx-add-to-queue font-size-16"></i> Agregar</b-button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card">
                                                <div class="card-body">
                                                    <h4 class="card-title">Productos</h4>
                                                    <div class="row mt-4">
                                                    <div class="col-sm-12 col-md-6">
                                                        <div id="tickets-table_length" class="dataTables_length">
                                                        <label class="d-inline-flex align-items-center">
                                                            Mostrar&nbsp;
                                                            <b-form-select v-model="perPage2" size="sm" :options="pageOptions2"></b-form-select>&nbsp;filas
                                                        </label>
                                                        </div>
                                                    </div>
                                                    <!-- Search -->
                                                    <div class="col-sm-12 col-md-6">
                                                        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                                        <label class="d-inline-flex align-items-center">
                                                            Buscar:
                                                            <b-form-input
                                                            v-model="filter"
                                                            type="search"
                                                            placeholder="Buscar..."
                                                            class="form-control form-control-sm ml-2"
                                                            ></b-form-input>
                                                        </label>
                                                        </div>
                                                    </div>
                                                    <!-- End search -->
                                                    </div>
                                                    <!-- Table -->
                                                    <div class="table-responsive mb-0">
                                                    <b-table
                                                        :items="productos_tabla"
                                                        :fields="campos_productos"
                                                        responsive="sm"
                                                        :per-page="perPage2"
                                                        :current-page="currentPage"
                                                        :sort-by.sync="sortBy"
                                                        :sort-desc.sync="sortDesc"
                                                        :filter="filter"
                                                        :filter-included-fields="filterOn"
                                                        @filtered="onFiltered2"
                                                    >
                                                    <template v-slot:cell(precio)="data">
                                                        <p> {{"$"+Intl.NumberFormat("de-DE").format(data.item.precio)}}</p>
                                                    </template>
                                                    <template v-slot:cell(accion)="data">
                                                        <b-button size="sm" variant="danger" @click="editarPhone(data.index)">Editar</b-button>
                                                        <b-button size="sm" variant="danger" @click="deletePhone(data.item)">Eliminar</b-button>
                                                    </template>
                                                    </b-table>
                                                    </div>
                                                    <div class="row">
                                                    <div class="col">
                                                        <div class="dataTables_paginate paging_simple_numbers float-right">
                                                        <ul class="pagination pagination-rounded mb-0">
                                                            <!-- pagination -->
                                                            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage2"></b-pagination>
                                                        </ul>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            </div>

                                        <!-- <div v-for="(data , index) in phoneData" :key="data.id" class="inner mb-3 row">
                                            <div class="col-md-6 col-8">
                                                <input v-model.lazy="data.phone" type="text" class="inner form-control" placeholder="Agregar producto" list="datalistOptions2"/>
                                                <datalist id="datalistOptions2" >
                                                    <option v-for="item in productos" :key="item.id"> {{item.codigo}} - {{item.nombre}} - {{item.marca}}</option>
                                                </datalist>
                                            </div>
                                            <div class="col-md-2 col-4">
                                                <b-form-input id="numero_imput_id" v-model.lazy="data.cantidad"  type="number" name="number" placeholder="Cantidad" min="0"></b-form-input>
                                            </div>
                                            <div class="col-md-2 col-4">
                                                <b-form-input id="numero_imput_id" v-model.lazy="data.precio"  type="number" name="number" placeholder="Precio" min="0"></b-form-input>
                                            </div>                                               
                                            <div class="col-md-2 col-4">
                                                <b-button variant="danger" block @click="deletePhone(index)"><i class="bx bx-trash font-size-16"></i> Eliminar</b-button>
                                            </div>
                                        </div> -->
                                    </div>
                                    <!-- <input type="button" class="btn btn-info inner" value="Agregar" @click="AddPhoneNo"/> -->
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="text-right">
                        <b-button variant="info" @click="Volvermodal_1()">Volver</b-button>
                        <b-button variant="info" @click="Crearlicitacion()">Ver vista previa</b-button>
                    </div>
                    <!---------- Modal ---------->
                    <b-modal
                    ref='Editar_Productos_Licitacion'
                    title='Editar producto'
                    hide-footer
                    size='md'
                    @hidden='Hidemodal(1)'
                    :no-close-on-backdrop='false'>
                        <template v-if="EstadoProducto">
                            <b-form-group id="input-group-1" label="Cantidad:" label-for="input-1">
                                <b-form-input id="input-1" v-model="CantidadModificar" type="number" min="0" required></b-form-input>
                                <div class="text-left text-danger mt-1">
                                    <p v-if="AlertaCantidad" class="badge position-absolute">
                                        Debe ingresar Cantidad
                                    </p>
                                </div>
                            </b-form-group>
                            
                            <b-form-group class="mt-1" id="input-group-2" label="Precio:" label-for="input-2">
                                <b-form-input id="input-2" v-model="CantidadPrecio" type="number" min="0" required></b-form-input>
                                <div class="text-left text-danger mt-1">
                                    <p v-if="AlertaPrecio" class="badge position-absolute">
                                        Debe ingresar Cantidad
                                    </p>
                                </div>
                            </b-form-group>
                            <!---------- Botón ---------->
                            <b-button class="mt-2" size='sm' variant='info' @click='actualizar_producto()'>Actualizar</b-button>
                            <b-button class="mt-2" size='sm' variant='info' @click='Hidemodal(1)'>Volver</b-button>
                        </template>
                    </b-modal>
                </b-modal>
                <b-modal ref="Vista_Licitacion" hide-footer size="xl" @hidden="Volver_vistaprevia()">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-10 text-center">
                                    <h1>Vista previa licitación </h1>
                                </div>
                                <div class="col-2 text-right">
                                    <b-button  variant="info" @click="Volver_vistaprevia()">Volver</b-button>
                                    <b-button  variant="info" @click="Crearlicitacion_final()" :disabled="showbutton_crearlicitacion">Crear</b-button>  
                                </div>
                            </div>
                            <vue-html2pdf class="d-flex justify-content-center"
                                :show-layout="true"
                                :float-layout="false"
                                :enable-download="true"
                                :preview-modal="false"
                                :paginate-elements-by-height="1400"
                                :filename="'Licitación_'+ Componentes_Licitacion.numero+'_'+ Componentes_Licitacion.cliente"
                                :pdf-quality="2"
                                :manual-pagination="false"
                                pdf-format="legal"
                                pdf-orientation="portrait"
                                pdf-content-width="800px"
                                @hasStartedGeneration="hasStartedGeneration()"
                                @hasGenerated="hasGenerated($event)"
                                ref="html2Pdf"
                            >
                                <section slot="pdf-content">
                                        <!-- PDF Content Here -->
                                        <section class="pdf-item pt-5 pl-5 pr-5" id="word">
                                            <div class="row pb-3 ">
                                                <div class="col-md-12 text-center" style="color:#000000">
                                                    <img src="/archivos_base/logo03.png" height="80" alt="logo">     
                                                </div>
                                                <div class="col-md-12">
                                                    <p class="p-0 m-0 font-size-16">ID: {{Componentes_Licitacion.numero}}</p> 
                                                    <p class="p-0 m-0 font-size-16">Nombre: {{Componentes_Licitacion.nombre}}</p> 
                                                    <p class="p-0 m-0 font-size-16">Cliente: {{Componentes_Licitacion.cliente}}</p>   
                                                    <p class="p-0 m-0 font-size-16">Condición: {{Componentes_Licitacion.condicion}}</p> 
                                                </div>
                                            </div>
                                            <div class="pt-3" style="color:#000000" v-for="(item,index) in Componentes_Licitacion.productos" :key="index">
                                                             
                                                <p class="font-size-16">Código interno: {{item.codigo}}</p>
                                                <p class="font-size-16">Nombre del producto: {{item.nombre}}</p>
                                                <p v-if="item.marca!='null'" class="font-size-16">Marca: {{item.marca}}</p>
                                                <p v-if="item.fabricacion!='null'" class="font-size-16">Fabricación: {{item.fabricacion}}</p>
                                                <p v-if="item.vencimiento!='null'" class="font-size-16">Fecha de vencimiento: {{item.vencimiento}}</p>
                                                <p v-if="item.presentacion!='null'" class="font-size-16">Presentación: {{item.presentacion}}</p>
                                                <!--
                                                <p v-if="item.envio!='null'" class="font-size-16">Envío: {{item.envio}}</p>
                                                <p v-if="item.plazoentrega!='null'" class="font-size-16">Plazo de entrega: {{item.plazoentrega}}</p>
                                                -->
                                                <p v-if="item.caracteristicas!='null'" class="font-size-16">Caracteristicas: {{item.caracteristicas}}</p>
                                                <div class="text-center">
                                                    <img :src="item.foto_dir_resize" alt="imgproducto" class="mb-3"/>
                                                </div> 
                                                <div v-for="i in 30" :key="i">
                                                    <br>
                                                </div>
                                                <div class="html2pdf__page-break"/>
                                            </div>
                                            <div class="row pb-3 ">
                                                    <div class="col-md-12 text-center" style="color:#000000">
                                                        <img src="/archivos_base/logo.png" height="50" alt="logo"/> 
                                                        <p class="p-0 m-0">Dirección: Errázuriz 2066 – Valdivia</p>
                                                        <p class="p-0 m-0">Teléfono: +56 63 2 204385</p>
                                                        <p class="p-0 m-0">Email: contacto@vamproden.cl</p>
                                                        <p class="p-0 m-0">www.vamproden.cl</p>
                                                    </div>
                                                </div>
                                        </section> 
                                    </section>
                            </vue-html2pdf>
                        </div>
                    </div>
                </b-modal>
                <div class="col-sm-4">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex align-items-center mb-3">
                                <div class="avatar-xs mr-3">
                                    <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                                        <i class="bx bx bxs-group"></i>
                                    </span>
                                </div>
                                <a href="/clientes" class="font-size-20 mb-0">Clientes</a>
                            </div>
                            <div class="text-muted mt-4">
                                <div class="col-md-12 text-center">
                                    <h4 class="text-primary">Total: {{clientes.length}}</h4>
                                    <b-button  variant="info" @click="CrearClientemodal()">Agregar Cliente</b-button>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>
                <b-modal ref="Crear_Cliente" title="Crear Cliente" hide-footer size="xl">
                    <div class="row">
                        <div class="col-12">
                            <form class="form-horizontal" role="form">
                                <b-form-group id="rut_id" label-cols-sm="2" label-cols-lg="2" label="Rut" label-for="text">
                                    <div class="row">
                                        <div class="col-md-10 col-8">
                                            <b-form-input for="text" v-model="Componentes_Cliente.rut" placeholder="Ingrese rut sin puntos y con guión"></b-form-input>
                                        </div>
                                        <div class="col-md-2 col-4">
                                            <b-button variant="info" block @click="validarrut()">Validar</b-button>
                                        </div>
                                    </div>
                                </b-form-group>

                                <b-form-group id="Nombre_id" label-cols-sm="2" label-cols-lg="2" label="Razón social" label-for="text" v-if="Mostrar_cliente">
                                    <b-form-input for="text" v-model="Componentes_Cliente.nombre" readonly></b-form-input>
                                </b-form-group>

                                <b-form-group id="Nombre_id" label-cols-sm="2" label-cols-lg="2" label="Nombre fantasía" label-for="text" v-if="Mostrar_cliente">
                                    <b-form-input for="text" v-model="Componentes_Cliente.fantasia" placeholder="Ingrese nombre de fantasía"></b-form-input>
                                </b-form-group>
                            </form>
                        </div>
                    </div>
                    <div class="text-right">
                        <b-button variant="info" @click="Volver()">Volver</b-button>
                        <b-button :disabled="Mostrar_boton_crear" variant="success" @click="CrearCliente()">Crear</b-button>
                    </div>
                </b-modal>
                <div class="col-sm-4 mt-4">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex align-items-center mb-3">
                                <div class="avatar-xs mr-3">
                                    <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                                        <i class="bx bxs-package"></i>
                                    </span>
                                </div>
                                <a class="font-size-20 mb-0">Despachos</a>
                            </div>
                            <div class="row text-muted mt-4">
                                <div class="col-md-12 text-center">
                                    <h4 class="text-primary"> Pendientes: {{cantidad_pendientes.length}}</h4>
                                </div>      
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
